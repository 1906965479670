import React, { useState } from 'react';
import { Github, ExternalLink, Award, Star } from 'lucide-react';
import ScreenHeading from '../../utilities/ScreenHeading/ScreenHeading';
import ScrollService from '../../utilities/ScrollService';
import Animations from '../../utilities/Animations';

const ProjectCard = ({ project }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="bg-gradient-to-br from-slate-900 to-slate-800 text-white p-6 rounded-lg shadow-lg hover:shadow-blue-500/20 transition-transform duration-300 hover:-translate-y-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="border-b border-gray-700 pb-4 mb-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            {project.title}
          </h2>
          {project.featured && (
            <div className="flex items-center text-yellow-400">
              <Star className="w-5 h-5 mr-1 fill-current" />
              <span className="text-sm">Featured</span>
            </div>
          )}
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
          {project.technologies.map((tech, index) => (
            <span
              key={index}
              className="px-3 py-1 text-sm rounded-full bg-blue-500/20 text-blue-300"
            >
              {tech}
            </span>
          ))}
        </div>
      </div>

      <div className="relative overflow-hidden">
        <img
          src={project.image || '/api/placeholder/400/200'}
          alt={project.title}
          className="md:w-full h-48 object-contain rounded-lg transition-transform duration-500 group-hover:scale-105 object-center"
        />
        {isHovered && (
          <div className="absolute inset-0 bg-black/70 flex items-center justify-center text-center p-4">
            <p className="text-white text-sm">{project.description}</p>
          </div>
        )}
      </div>

      {project.achievements && (
        <div
          className={`mt-4 flex items-center text-blue-300 ${
            project.achievements.trim() === '&nbsp;' ? 'invisible' : ''
          }`}
        >
          <Award className="w-5 h-5 mr-2" />
          <p className="text-sm">{project.achievements}</p>
        </div>
      )}

      <div className="border-t border-gray-700 pt-4 mt-4 flex flex-col justify-start mx-auto">
        <div className="flex space-x-2">
          {project.stats &&
            project.stats.map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-lg font-bold text-blue-400">
                  {stat.value}
                </div>
                <div className="text-xs text-gray-400">{stat.label}</div>
              </div>
            ))}
        </div>
        <div className="flex space-x-4 pt-4 ">
          {project.github && (
            <a
              href={project.github}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center px-4 py-2 rounded-lg bg-blue-500/20 text-blue-300 hover:bg-blue-500/30 transition-colors duration-300"
            >
              <Github className="w-5 h-5 mr-2" />
              Code
            </a>
          )}
          {project.demo && (
            <a
              href={project.demo}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center px-4 py-2 rounded-lg bg-purple-500/20 text-purple-300 hover:bg-purple-500/30 transition-colors duration-300"
            >
              <ExternalLink className="w-5 h-5 mr-2" />
              Demo
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const Projects = (props) => {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // const fadeInSubscription = //turn on later
  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const projectList = [
    {
      title: 'AI Learning Wrapped',
      technologies: [
        'Google Generative AI',
        'Typescript',
        'Google Cloud',
        'Next.JS',
      ],
      image: 'img/math_playground.png',
      description:
        'AI-powered platform enhancing student-teacher communication',
      github: 'https://github.com/chrisbolosan/learning-wrapped',
      demo: 'https://learning-wrapped.vercel.app/spaces',
      featured: true,
      achievements: 'Finalist for RealityAI Hackathon',
      stats: [
        { value: '100%', label: 'Uptime' },
        { value: '4.9', label: 'Rating' },
      ],
    },
    {
      title: 'AI Plagarism Detector',
      image: 'img/plagarism.png',
      technologies: ['Google Generative AI', 'Typescript', 'Next.JS', 'React'],
      description: 'AI-powered Plagarism Detector',
      github: '',
      demo: 'https://plagarism-ai.vercel.app/',
      achievements: '&nbsp;',
      stats: [
        { value: '100+', label: 'Users' },
        { value: '4.8', label: 'Rating' },
      ],
    },
    {
      title: 'Ricoma.com',
      image: 'img/ricoma-logo.svg',
      technologies: [
        'PHP',
        'Javascript',
        'MySQL',
        'Vue.js',
        'HubSpot',
        'Oracle',
      ],
      description: 'Ricoma Commercial Site',
      github: '',
      demo: 'https://ricoma.com/US',
      achievements: '&nbsp;',
      stats: [
        { value: 'Worldwide', label: 'Users' },
        { value: '4.8', label: 'Rating' },
      ],
    },
    {
      title: 'Ricoma Landing Pages',
      image: 'img/ricoma-logo.svg',
      technologies: ['Vite', 'Javascript', 'React', 'HubSpot', 'Oracle'],
      description: 'Marketing Pipeline for Ricoma Landing Pages',
      github: '',
      demo: 'https://info.ricoma.com/',
      achievements: '&nbsp;',
      stats: [
        { value: 'Worldwide', label: 'Users' },
        { value: '5.0', label: 'Rating' },
      ],
    },
    {
      title: 'Ricoma E-Commerce Shop',
      image: 'img/ricoma-logo.svg',
      technologies: ['Shopify', 'Javascript', 'HubSpot', 'SCSS', 'Oracle'],
      description: 'Ricoma E-Commerce Store',
      github: '',
      demo: 'https://shop.ricoma.com/',
      achievements: '&nbsp;',
      stats: [
        { value: 'Worldwide', label: 'Users' },
        { value: '5.0', label: 'Rating' },
      ],
    },
    {
      title: 'Water Pump Visualizer',
      image: 'img/water.png',
      technologies: ['Python', 'Streamlit', 'NumPy', 'Pygame'],
      description: 'Water Pump Mathmatic Visualizer ',
      github:
        'https://github.com/chrisbolosan/mathforge/blob/main/cylinder_visualization.py',
      demo: 'https://fluid-work.streamlit.app/',
      achievements: '&nbsp;',
      stats: [
        { value: '15', label: 'Users' },
        { value: '5.0', label: 'Rating' },
      ],
    },
  ];

  return (
    <section className="w-full py-16 ">
      <div className="max-w-6xl mx-auto px-4">
        <ScreenHeading title={'Projects'} subHeading={'Explore My Work'} />

        {/* Responsive Grid Layout */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {projectList.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
